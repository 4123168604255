@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Raleway&display=swap');
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import './styling/button-group';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';

$primary: #092d4f;
$secondary: #f5543d;

$table-top-border-color: #212529;

$link-color: $primary;
$link-decoration: none;
$link-hover-decoration: underline;

$headings-font-family: 'Raleway';
$font-family-sans-serif: 'Open Sans', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$input-disabled-bg: $gray-100;

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';

.btn-main {
  @include button-variant(
    $light,
    $border-color,
    $body-color,
    $hover-background: darken($light, 2%),
    $active-background: darken($light, 5%)
  );
}

.btn-outline-primary {
  @include button-variant(
    #ffffff,
    $primary,
    $primary,
    $hover-background: $primary,
    $active-background: $primary
  );
}

.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    background-color: darken($primary, 2%);
    border-color: darken($primary, 2%);
  }
}

progress hr {
  opacity: 1;
  margin-bottom: 2rem;
  margin-top: 2rem;
  height: 1px;
  background: var(--button-border-color);
  border: none;
}

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'border-color': $border-color,
  'link-color': $link-color,
  'code-color': $code-color,
  'navbar-light-hover-color': $navbar-light-hover-color,
  'navbar-light-active-color': $navbar-light-active-color,
  'input-disabled-bg': $input-disabled-bg,
  'text-muted': $text-muted,
  'input-border-color': $input-border-color,
  'light': $light,
  'warning': $warning,
  'danger': $danger,
  'info': $info,
  'success': $success,
  'table-top-border-color': $table-top-border-color,
  'button-border-color': #dee2e6,
);

$spacings: (
  'grid-gutter-width': $grid-gutter-width,
  'headings-margin-bottom': $headings-margin-bottom,
);

$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px,
);

$fonts: (
  'headings-font-family': $headings-font-family,
  'font-family-code': $font-family-code,
  'font-family-sans-serif': $font-family-sans-serif,
  'link-decoration': $link-decoration,
  'link-hover-decoration': $link-hover-decoration,
  'h3-font-size': $h3-font-size,
  'h4-font-size': $h4-font-size,
  'font-size-base': $font-size-base,
  'font-size-sm': $font-size-sm,
  'font-size-lg': $font-size-lg,
);

// Helpers
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/breadcrumb';

.contact-badge {
  @extend .badge !optional;

  margin-right: 0.5rem;

  &-info {
    background-color: $gray-300;
    color: darken($light, 75%) !important;
  }
  &-warning {
    background-color: $warning;
    color: shade-color($warning, 70%) !important;
  }
}

%content-width {
  max-width: 42rem;
}

.page-header-introduction {
  @extend %content-width;
}

.badge,
.contact-badge {
  --bs-badge-border-radius: 0.25rem;
}

.form-control {
  border-radius: 0.25rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(9 45 79 / 25%);
  border-color: #8496a7;
}

.washed-out-blue {
  background-color: tint-color($primary, 50%) !important;
  color: white;
}

.washed-out-blue.progress-bar.bg-primary:hover {
  background-color: tint-color($primary, 50%) !important;
  color: white;
}

.washed-out-blue:hover {
  background-color: tint-color($primary, 50%) !important;
  color: white;
}

.badge,
th {
  font-weight: 400;
}

:root {
  --bs-primary: #092d4f;
  --bs-secondary: #f5543d;
  --bs-primary-rgb: 9, 45, 79;
  --bs-secondary-rgb: 245, 84, 61;
  @each $name, $color in $theme-colors {
    --#{$name}: #{$color};
  }
  @each $name, $font in $fonts {
    --#{$name}: #{$font};
  }
  @each $name, $spacing in $spacings {
    --#{$name}: #{$spacing};
  }
}
